<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Agregar Donador Frecuente</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-divider />
        <v-container>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-select
                label="Tipo de Persona"
                required
                outlined
                hint="Si es una empresa, seleccione Persona Jurídica"
                persistent-hint
                :items="person_types"
                item-text="person_type"
                item-value="person_type"
                v-model="Donor.person_type"
                @change="personType"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="NIT"
                outlined
                hide-details
                v-model="Donor.nit"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col ols="12" sm="6" v-if="legal">
              <v-text-field
                label="Nombre de la Institución"
                required
                outlined
                hide-details
                v-model="Donor.institution_name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="legal">
              <v-file-input
                label="Subir NIT de la institución"
                outlined
                hint="Copia del NIT en formato PDF"
                persistent-hint
                prepend-icon
                prepend-inner-icon="mdi-pdf-box"
                show-size
                accept="application/pdf"
                v-model="Donor.photo_nit"
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Seleccione su país"
                outlined
                hide-details
                v-model="Donor.id_country"
                :items="countries"
                item-text="country"
                item-value="id_country"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Documento Único de Identidad"
                :hint="hintDui"
                persistent-hint
                type="text"
                required
                outlined
                v-model="Donor.dui"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                label="Nombre completo según Documento"
                required
                outlined
                hide-details
                v-model="Donor.name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" v-if="!legal" class="d-none">
              <v-text-field
                label="NIT"
                outlined
                v-model="Donor.nit"
                hint="El NIT es opcional, no es obligatorio si no posee este dato"
                persistent-hint
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="Correo electrónico"
                type="email"
                required
                outlined
                hide-details
                v-model="Donor.email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Teléfono"
                type="phone"
                required
                outlined
                hide-details
                v-model="Donor.phone"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Año de promoción"
                required
                outlined
                persistent-hint
                hint="Si fue ex alumno, por favor indique su promoción"
                v-model="Donor.promotion"
                :items="promotions"
                item-text="promotion"
                item-value="promotion"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Promesa de donación anual"
                required
                outlined
                hide-details=""
                v-model="Donor.goal_group"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Seleccione Grupo de Donación"
                required
                outlined
                hide-details
                v-model="Donor.id_group"
                :items="groups"
                item-text="group"
                item-value="id_group"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-textarea
                label="Dirección de domicilio"
                required
                type="text"
                rows="1"
                row-height="15"
                auto-grow
                outlined
                hide-details
                v-model="Donor.address"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add()" :loading="isLoading">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddDonorFrequent",
  data() {
    return {
      person_types: ["Natural", "Juridica"],
      legal: false,
      countries: [],
      promotions: [],
      groups: [],
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    Donor: Object,
    clean: {
      type: Function,
    },
  },
  mounted() {
    this.getCountries();
    this.getPromotions();
    this.getGroups();
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    add: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "donor/frequent", this.Donor)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getGroups: async function() {
      await this.$http
        .get(this.api + "group")
        .then(({ data }) => {
          this.groups = data;
        })
        .catch((err) => {
          console.error("Error al obtener Grupos de Donadores: " + err);
        });
    },

    getCountries: async function() {
      await this.$http.get(this.api + "country").then(({ data }) => {
        this.countries = data;
      });
    },

    getPromotions: function() {
      let notProm = "No soy ex-alumno";
      this.promotions.push(notProm);
      let year = new Date().getFullYear();
      year--;
      for (year; year > 1960; year--) {
        this.promotions.push(year);
      }
    },

    getGroups: function() {
      this.$http
        .get(this.api + "group")
        .then(({ data }) => {
          this.groups = data;
        })
        .catch((err) => {
          console.error("Error al obtener Grupos de Donadores: " + err);
        });
    },

    personType: function() {
      if (this.Donor.person_type == "Juridica") {
        this.legal = true;
      } else {
        this.legal = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
    hintDui() {
      return this.legal ? "Del Representante Legal" : "";
    },
  },
};
</script>

<style></style>
