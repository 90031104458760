<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">
          Donadores Frecuentes
        </p>
        <v-btn
          color="primary"
          elevation="0"
          @click="modalAdd"
          v-if="searchInArray(actions, 'Agregar')"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar Donador Frecuente</span>
          <span class="d-flex d-sm-none">Agregar</span>
        </v-btn>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat height="50">
                  <v-toolbar-title class="text-h5">
                    Lista de Donadores Frecuentes
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectDonor(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Activar/Desactivar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectDonor(item);
                          modalChangeStatus();
                        "
                        :color="
                          item.status == 1
                            ? 'light-blue darken-3'
                            : 'blue-grey darken-1'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          item.status == 1
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                    <span>
                      {{ item.status == 1 ? "Desactivar" : "Activar" }}
                    </span>
                  </v-tooltip>
                </template>
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectDonor(item);
                          modalDonorInfo();
                        "
                        color="deep-purple"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Ver información</span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getDonors">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modales -->
    <AddDonorFrequent
      ref="mdlAdd"
      :Donor="newDonor"
      :clean="cleanAdd"
    ></AddDonorFrequent>
    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-card-text class="text-center">
          <p class="mb-0">¿Está seguro de eliminar este elemento?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">
            Cancelar
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteDonorConfirm"
            :loading="isLoading"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogChangeStatus"
      persistent
      transition="dialog-bottom-transition"
      max-width="450"
    >
      <v-form>
        <v-card>
          <v-card-title class="text-h6">
            <div class="d-flex flex-row">
              <v-icon medium color="cyan" left>mdi-alert</v-icon>
              ¿Desea cambiar el estado del donador frecuente?
            </div>
          </v-card-title>
          <v-card-text class="text-body-1 black--text text-justify">
            Atención: si confirma el donador
            <span class="font-weight-bold">{{ slcDonor.name }}</span
            >, se
            {{
              slcDonor.status == 1
                ? "desactivará y no será visible"
                : "activará y volverá a ser visible"
            }}.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text outlined class="mr-1" @click="closeChangeStatus"
              >Cancelar</v-btn
            >
            <v-btn
              color="cyan"
              class="white--text"
              depressed
              :loading="isLoading"
              @click="changeStatus"
              >Si, {{ slcDonor.status == 1 ? "desactivar" : "activar" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="dialogDonorInfo"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-form>
        <v-card>
          <v-card-title class="text-h6">
            <div class="d-flex flex-row">
              <!-- <v-icon medium color="cyan" left>mdi-alert</v-icon> -->
              Información del Donador
            </div>
          </v-card-title>
          <v-card-text class="text-h6 black--text text-justify">
            <div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Nombre del donador:</div>
                <span class="font-weight-medium">{{ slcDonor.name }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Correo electrónico:</div>
                <span class="font-weight-medium">{{ slcDonor.email }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Teléfono del donador:</div>
                <span class="font-weight-medium">{{ slcDonor.phone }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Dirección del domicilio:</div>
                <span class="font-weight-medium">{{ slcDonor.address }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">DUI:</div>
                <span class="font-weight-medium">{{ slcDonor.dui }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">NIT:</div>
                <span class="font-weight-medium">{{ slcDonor.nit }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Promoción:</div>
                <span class="font-weight-medium">{{ slcDonor.promotion }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Tipo de persona:</div>
                <span class="font-weight-medium">{{
                  slcDonor.person_type
                }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Institución:</div>
                <span class="font-weight-medium">{{
                  slcDonor.institution_name
                }}</span>
              </div>
              <div class="mb-1 flex-column">
                <div class="text-body-2">Grupo de donación:</div>
                <span class="font-weight-medium">{{ slcDonor.group }}</span>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text outlined class="mr-1" @click="closeDonorInfo"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddDonorFrequent from "./AddDonorFrequent";

export default {
  name: "Donor",
  components: {
    AddDonorFrequent,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "phone" },
        { text: "Promoción", value: "promotion" },
        { text: "Tipo de persona", value: "person_type" },
        { text: "Institución", value: "institution_name" },
        { text: "Grupo", value: "group" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
      data: [],
    },
    newDonor: {},
    slcDonor: {},
    dialogDelete: false,
    isLoading: false,
    search: "",
    dialogChangeStatus: false,
    dialogDonorInfo: false,
  }),
  mounted() {
    this.getCurrentActions("Donadores Frecuentes");
    this.getDonors();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),

    //funcionalidad
    getDonors: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "donor/all/frequent_donors")
        .then(({ data }) => {
          data.forEach((item) => {
            if (item.institution_name == null) {
              item.institution_name = "Ninguna";
            }
            if (item.group == null) {
              item.group = "Ninguno";
            }
            if (item.nit == null) {
              item.nit = "Sin especificar";
            }
            if (item.frequent_donation == null) {
              item.frequent_donation = "Ninguna";
            }
          });
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectDonor(Donor) {
      this.slcDonor = Donor;
    },

    cleanAdd() {
      this.newDonor = {};
      this.getDonors();
    },

    modalAdd() {
      this.$refs.mdlAdd.changeModal();
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcDonor = {};
      this.getDonors();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    modalChangeStatus() {
      this.dialogChangeStatus = true;
    },

    modalDonorInfo() {
      this.dialogDonorInfo = true;
    },

    closeDonorInfo() {
      this.dialogDonorInfo = false;
      this.$nextTick(() => {
        this.slcDonor = {};
      });
    },

    deleteDonorConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "donor/" + this.slcDonor.id_donor)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + "donor/status/" + this.slcDonor.id_donor)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
            this.closeChangeStatus();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcDonor = {};
      });
    },

    closeChangeStatus() {
      this.dialogChangeStatus = false;
      this.$nextTick(() => {
        this.slcDonor = {};
      });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
